import { createAsyncThunk } from '@reduxjs/toolkit'
import * as API from './repository'
import { useSelector } from 'react-redux'
import {
  setErrorGlobalMessage,
  setSuccessGlobalMessage,
} from '../../../core/ui/ui.slice'
import { handleError } from '../../../helpers/handleError'
import messages from './../../../constants/messages'
import { selectMaterialId } from './index'
import { resourcesList } from './helpers'

export const fetchMaterials = createAsyncThunk(
  'Materials/fetchMaterials',
  async (type) => {
    const materialsAndCampaignsList = await API.fetchAllMaterials(type)
    return resourcesList(materialsAndCampaignsList)
  }
)

export const updateMaterialStatus = createAsyncThunk(
  'Materials/updateMaterialStatus',
  async ({ material, state, observation, directPage}, thunkAPI) => {
    try {
      console.log(material,'material')
        await API.updateResourceStatus(
          material.resourceId,
          material.advertisingTypeId,
          state,
          observation
        )
      // const selectedTab = useSelector((state) => state.OBT.selectedTab);
      // let directPage = selectedTab == "ET ADS" ? 1 : 2 ;

      thunkAPI.dispatch(fetchMaterials(directPage))
      thunkAPI.dispatch(setSuccessGlobalMessage())
      return {
        id: selectMaterialId(material),
        changes: { resourceStatus: state },
      }
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error),
        })
      )
      throw error
    }
  }
)

export const mathildeStatus = createAsyncThunk(
  'Materials/mathildeStatus',
  async ({ material }, thunkAPI) => {
    try {
      await API.mathildeStatusApprove(
        material.advertisingId
      ); 
      await thunkAPI.dispatch(fetchMaterials())
      thunkAPI.dispatch(setSuccessGlobalMessage())
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error),
        })
      )
      throw error
    }    
  }
)

export const replaceResource = createAsyncThunk(
  'Materials/replaceResource',
  async ({ advertisingId, file, directPage, advertisingTypeId }, thunkAPI) => {
    try {
      await API.replaceResource(advertisingId, file, advertisingTypeId)
      await thunkAPI.dispatch(fetchMaterials(directPage))
      thunkAPI.dispatch(setSuccessGlobalMessage())
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error),
        })
      )
      throw error
    }
  }
)

export const uploadReport = createAsyncThunk(
  'Materials/uploadReport',
  async ({ advertisingId, advertisingTypeId, file }, thunkAPI) => {
    try {
      await API.uploadReport(advertisingId, advertisingTypeId, file)
      thunkAPI.dispatch(fetchMaterials())
      thunkAPI.dispatch(setSuccessGlobalMessage())
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error, messages.messageErrorUploadingPdf),
        })
      )
      throw error
    }
  }
)

export const updateSmartReport = createAsyncThunk(
  'Materials/updateSmartReport',
  async ({ material, urlSmart }, thunkAPI) => {
    try {
      await API.updateSmartReport(material.advertisingId, urlSmart)
      thunkAPI.dispatch(setSuccessGlobalMessage())
      return {
        id: selectMaterialId(material),
        changes: { advertisingSmartTrackingLink: urlSmart },
      }
    } catch (error) {
      thunkAPI.dispatch(
        setErrorGlobalMessage({
          message: handleError(error),
        })
      )
      throw error
    }
  }
)
