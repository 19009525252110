export const listCondolenceTitle = [
  {
    value: 1,
    name: ' ',
  }, {
    value: 2,
    name: 'El señor',
  },
  {
    value: 3,
    name: 'La señora',
  },
]

export const listCondolenceHeader = [
  {
    value: 1,
    name: 'Descansó en paz'
  },
  {
    value: 2,
    name: 'Descansó en la paz del Señor'
  },
  {
    value: 3,
    name: 'Estará siempre con nosotros'
  },
]

export const listCondolenceInsignia = [
  {
    value: 1,
    name: ' '
  },
  {
    value: 2,
    name: 'cruz'
  },
  {
    value: 3,
    name: 'paloma'
  },
  {
    value: 4,
    name: 'iglesia'
  },
  {
    value: 5,
    name: 'cinta'
  }
]
export const listCondolenceImage = [
  {
    value: 1,
    name: 'Si'
  },
  {
    value: 2,
    name: 'No'
  }
]

export const listCondolenceCeremony = [
  {value:1,
    name: ' '
  },
  {value:2,
    name:'Misa'
  },
  {value: 3,
    name: 'Misa de exequias'
  },
  {value: 4,
    name: 'Exequias'
  },
  {value: 5,
    name: 'Velación'
  }
]
export const listCondolenceHour=[
  {name: '12:00 AM', value: '12:00 AM'}, {name: '12:30 AM', value: '12:30 AM'},
  {name: '01:00 AM', value: '01:00 AM'}, {name: '01:30 AM', value: '01:30 AM'},
  {name: '02:00 AM', value: '02:00 AM'}, {name: '02:30 AM', value: '02:30 AM'},
  {name: '03:00 AM', value: '03:00 AM'}, {name: '03:30 AM', value: '03:30 AM'},
  {name: '04:00 AM', value: '04:00 AM'}, {name: '04:30 AM', value: '04:30 AM'},
  {name: '05:00 AM', value: '05:00 AM'}, {name: '05:30 AM', value: '05:30 AM'},
  {name: '06:00 AM', value: '06:00 AM'}, {name: '06:30 AM', value: '06:30 AM'},
  {name: '07:00 AM', value: '07:00 AM'}, {name: '07:30 AM', value: '07:30 AM'},
  {name: '08:00 AM', value: '08:00 AM'}, {name: '08:30 AM', value: '08:30 AM'},
  {name: '09:00 AM', value: '09:00 AM'}, {name: '09:30 AM', value: '09:30 AM'},
  {name: '10:00 AM', value: '10:00 AM'}, {name: '10:30 AM', value: '10:30 AM'},
  {name: '11:00 AM', value: '11:00 AM'}, {name: '11:30 AM', value: '11:30 AM'},
  {name: '12:00 PM', value: '12:00 PM'}, {name: '12:30 PM', value: '12:30 PM'},
  {name: '01:00 PM', value: '01:00 PM'}, {name: '01:30 PM', value: '01:30 PM'},
  {name: '02:00 PM', value: '02:00 PM'}, {name: '02:30 PM', value: '02:30 PM'},
  {name: '03:00 PM', value: '03:00 PM'}, {name: '03:30 PM', value: '03:30 PM'},
  {name: '04:00 PM', value: '04:00 PM'}, {name: '04:30 PM', value: '04:30 PM'},
  {name: '05:00 PM', value: '05:00 PM'}, {name: '05:30 PM', value: '05:30 PM'},
  {name: '06:00 PM', value: '06:00 PM'}, {name: '06:30 PM', value: '06:30 PM'},
  {name: '07:00 PM', value: '07:00 PM'}, {name: '07:30 PM', value: '07:30 PM'},
  {name: '08:00 PM', value: '08:00 PM'}, {name: '08:30 PM', value: '08:30 PM'},
  {name: '09:00 PM', value: '09:00 PM'}, {name: '09:30 PM', value: '09:30 PM'},
  {name: '10:00 PM', value: '10:00 PM'}, {name: '10:30 PM', value: '10:30 PM'},
  {name: '11:00 PM', value: '11:00 PM'}, {name: '11:30 PM', value: '11:30 PM'}
]

export const listTypeImage =[
  {value:1,
   name:'Logo Empresarial'},
   {value:2,
    name:'Imagen'
   }
]