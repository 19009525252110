import axios from 'axios'
import qs from 'qs'
import { async } from 'regenerator-runtime'
import { API_ENDPOINT } from '../../../constants/apiEndpoint'
import { AdvertisingTypeCode } from './constants'
import { createAsyncThunk } from '@reduxjs/toolkit'

/**
 * Get list of resource with their relationships info (advertising, campaign, account)
 * @returns {response} response axios
 */
export const fetchAllMaterials = (type) => {
  const year = new Date();
  console.log(type,'type')
  const month = year.getMonth() + 1 < 10 ? "0" + (year.getMonth() + 1) : "" + (year.getMonth() + 1);
  const startDate = `${year.getFullYear() - 1}-${month}-01T00:00:00.000Z`;
  const endDate = `${year.getFullYear()}-12-31T00:00:00.000Z`;

  const url = `${API_ENDPOINT}/campaigns`
  return axios
    .get(url, {
      params: {
        filter: {
          where: { 
            manualStatus: { neq: 0 },
            productType: { eq : type == 2 ? 2 : 1 },
            createdAt: { gte: startDate, lte: endDate },
         },
          include: [
            {
              relation: 'advertisements',
              scope: {
                include: [
                  {
                    relation: 'resources',
                    scope: { where: { enabled: true } },
                  },
                  {
                    relation: 'resourcesClick',
                    scope: { where: { enabled: true } },
                  },
                  { relation: 'mathilde' },                  
                  { relation: 'advertisingType' },
                ],
              },
            },
            { relation: 'account' },            
            {
              relation: 'adSponsoredContents',
              scope: {
                include: [
                  {
                    relation: 'adSponsoredContentPricingAdContents',
                    scope: {
                      include: [
                        {
                          relation: 'pricingAdContent',
                          scope: {
                            include: [
                              {
                                relation: 'advertisingTypeDigitalProperties',
                                scope: {
                                  include: [{ relation: 'advertisingType' }],
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    relation: 'resourcesContents',
                    scope: { where: { enabled: true } },
                  },
                ],
              },
            },
          ],
        },
      },
    })
    .then((r) => r.data)
}

/**
 * Update resource status with adittional observation
 * @param {num} id
 * @param {num} typeId
 * @param {num} status
 * @param {string} observation
 * @returns {response} response axios
 */
export const updateResourceStatus = async (id, typeId, status, observation) => {
  let idFormat = await localStorage.getItem('idFormat')
  localStorage.setItem('status',status)
  let response
  const body = {
    status: status,
    observation: observation,
  }
  console.log(typeId,'typeId')
  if (typeId === AdvertisingTypeCode.Content) {
    response = await axios.patch(
      `${API_ENDPOINT}/resources_content/${id}/change-state`,
      body
    )
    return response
  } 
  if (typeId === AdvertisingTypeCode.CPC || typeId === AdvertisingTypeCode.CPCs) {
    response = await axios.patch(
      `${API_ENDPOINT}/resource-clicks/${id}/change-state/${idFormat}`,
      body
    )
    return response
  }
  if (typeId === AdvertisingTypeCode.OBT) {
    response = await axios.patch(
      `${API_ENDPOINT}/resources-condolences/${id}/change-state`,
      body
    )
    return response
  }
  else {
    response = await axios.patch(
      `${API_ENDPOINT}/resource/${id}/change-state`,
      body
    )
    return response
  }  
}

export const mathildeStatusApprove = async (id)=>{
  let url = `${API_ENDPOINT}/mathilde-createCampaign/${id}`
  return axios.get(url).then((r) => r.data)
}

/**
 * Replace resource for an advertising
 * @param {num} id
 * @param {obj} file
 * @returns
 */
export const replaceResource = async (id, file, advertisingTypeId) => {
  let idImgFormt = await localStorage.getItem('idImgFormt')
  if((advertisingTypeId == 5 || advertisingTypeId == 6) && idImgFormt){

    const formData = new FormData()
    formData.append('file', file)
    return axios.patch(
    `${API_ENDPOINT}/advertising/${id}/replace-resource-click/${idImgFormt}`,
    formData, { headers: { 'content-type': 'multipart/form-data' } })

  }else if(advertisingTypeId == 7){

    const formData = new FormData()
    formData.append('file', file)
    return axios.patch(
    `${API_ENDPOINT}/advertising-condolence/${id}/replace-resource`,
    formData, { headers: { 'content-type': 'multipart/form-data' } })

  }
  else{

    const formData = new FormData()
    formData.append('file', file)
    const response = await axios.patch(
      `${API_ENDPOINT}/advertising/${id}/replace-resource`,
      formData,
      {
        headers: {'content-type': 'multipart/form-data',},
      }
    )
    return response

  }
}

/**
 * Upload report file
 * @param {num} id
 * @param {obj} file
 * @returns
 */
export const uploadReport = async (id, typeId, file) => {
  const type =
    typeId === AdvertisingTypeCode.Content
      ? 'ad-sponsored-contents'
      : 'advertising'
  const formData = new FormData()
  formData.append('file', file)
  const response = await axios.patch(
    `${API_ENDPOINT}/${type}/${id}/report`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  )
  return response
}

export const updateSmartReport = async (advertisingId, urlSmart) => {
  const body = {
    smartTrackingLink: urlSmart,
  }
  let response = await axios.patch(
    `${API_ENDPOINT}/advertising/${advertisingId}`,
    body
  )
  return response
}
